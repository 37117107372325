import clsx from "clsx";
import { OTPInput, OTPInputContext } from "input-otp";
import * as React from "react";

export const InputOTP = React.forwardRef<
  React.ElementRef<typeof OTPInput>,
  Omit<
    React.ComponentPropsWithoutRef<typeof OTPInput>,
    "render" | "maxLength"
  > & {
    maxLength?: number;
  }
>(
  (
    { className, containerClassName, children, maxLength = 6, ...props },
    ref
  ) => {
    let _children = children;
    if (!children) {
      _children = (
        <InputOTPGroup>
          {Array.from({ length: maxLength }, (_, index) => (
            <InputOTPSlot key={index} index={index} />
          ))}
        </InputOTPGroup>
      );
    }

    return (
      <OTPInput
        {...props}
        ref={ref}
        containerClassName={clsx(
          "flex items-center gap-2 has-[:disabled]:opacity-50 mt-2",
          containerClassName
        )}
        className={clsx("disabled:cursor-not-allowed", className)}
        maxLength={maxLength}
      >
        {_children}
      </OTPInput>
    );
  }
);
InputOTP.displayName = "InputOTP";

export const InputOTPGroup = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div ref={ref} className={clsx("flex flex-1", className)} {...props} />
));
InputOTPGroup.displayName = "InputOTPGroup";

export const InputOTPSlot = React.forwardRef<
  React.ElementRef<"div">,
  React.ComponentPropsWithoutRef<"div"> & { index: number }
>(({ index, className, ...props }, ref) => {
  const inputOTPContext = React.useContext(OTPInputContext);
  const { char, hasFakeCaret, isActive } = inputOTPContext.slots[index];

  return (
    <div
      ref={ref}
      className={clsx(
        "relative flex flex-1 aspect-square items-center justify-center border-y border-r border-input text-xl sm:text-2xl md:text-3xl transition-all first:rounded-l-md first:border-l last:rounded-r-md",
        isActive && "z-10 ring-2 ring-action ring-offset-system-screen",
        className
      )}
      {...props}
    >
      {char}
      {hasFakeCaret ? (
        <div className="pointer-events-none absolute inset-0 flex items-center justify-center">
          <div className="h-4 w-px animate-caret-blink bg-fg-primary duration-1000" />
        </div>
      ) : null}
    </div>
  );
});
InputOTPSlot.displayName = "InputOTPSlot";
