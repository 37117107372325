import {
  Button as HeadlessButton,
  type ButtonProps as HeadlessButtonProps,
} from "@headlessui/react";
import clsx from "clsx";
import { forwardRef } from "react";

import { TouchTarget } from "../Button";
import { LinkComponentProps, useLinkComponent } from "../Link";

const colors = {
  brand:
    "bg-brand/20 text-brand group-data-[hover]:bg-brand/25 dark:bg-brand/10 dark:text-brand dark:group-data-[hover]:bg-brand/20",
  action:
    "bg-action/20 text-action group-data-[hover]:bg-action/25 dark:bg-action/10 dark:text-action dark:group-data-[hover]:bg-action/20",
  accent:
    "bg-accent/20 text-accent group-data-[hover]:bg-accent/30 dark:bg-accent/10 dark:text-accent dark:group-data-[hover]:bg-accent/15",
  system: [
    "bg-system-regular text-fg-primary group-data-[hover]:bg-system-thick",
    "dark:bg-system-regular-dark dark:text-fg-primary-dark dark:group-data-[hover]:bg-system-thick-dark",
  ],
  material: [
    "text-fg-primary dark:text-fg-primary-dark",
    "bg-material-regular dark:bg-material-regular group-data-[hover]:bg-material-thick dark:group-data-[hover]:bg-material-thick-dark",
  ],
  chrome: [
    "text-fg-primary dark:text-fg-primary-dark",
    "transition-all shadow-md group-data-[hover]:shadow-lg group-data-[hover]:-translate-y-0.5",
    "bg-system-chrome dark:bg-system-chrome-dark group-data-[hover]:bg-system-chrome dark:group-data-[hover]:bg-system-chrome-dark",
  ],
  red: "bg-red-500/15 text-red-700 group-data-[hover]:bg-red-500/25 dark:bg-red-500/10 dark:text-red-400 dark:group-data-[hover]:bg-red-500/20",
  orange:
    "bg-orange-500/15 text-orange-700 group-data-[hover]:bg-orange-500/25 dark:bg-orange-500/10 dark:text-orange-400 dark:group-data-[hover]:bg-orange-500/20",
  green:
    "bg-green-500/15 text-green-700 group-data-[hover]:bg-green-500/25 dark:bg-green-500/10 dark:text-green-400 dark:group-data-[hover]:bg-green-500/20",
};

interface BadgeProps {
  color?: keyof typeof colors;
}

export function Badge({
  color = "brand",
  className,
  ...props
}: BadgeProps & React.ComponentPropsWithoutRef<"span">) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        "inline-flex items-center gap-x-1.5 rounded-md px-1.5 py-0.5 text-sm/5 font-medium sm:text-xs/5 forced-colors:outline backdrop-blur-lg",
        colors[color]
      )}
    />
  );
}

export const BadgeButton = forwardRef(function BadgeButton(
  {
    color = "brand",
    className,
    children,
    ...props
  }: BadgeProps & { children: React.ReactNode } & (
      | HeadlessButtonProps
      | LinkComponentProps
    ),
  ref: React.ForwardedRef<HTMLElement>
) {
  const classes = clsx(
    className,
    "group relative inline-flex rounded-md",
    "focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-blue-500"
  );

  const Link = useLinkComponent();

  if ("href" in props || "to" in props) {
    return (
      <Link
        {...props}
        className={classes}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      >
        <TouchTarget>
          <Badge color={color}>{children}</Badge>
        </TouchTarget>
      </Link>
    );
  } else {
    const castedProps = props as HeadlessButtonProps;
    return (
      <HeadlessButton {...castedProps} className={classes} ref={ref}>
        <TouchTarget>
          <Badge color={color}>{children}</Badge>
        </TouchTarget>
      </HeadlessButton>
    );
  }
});
