import { graphql } from "@apollosproject/cluster-client";

export const EventItemFragment = graphql(`
  fragment EventItemFragment on Event {
    id
    title
    location
    start
    end
    htmlContent
    coverImage {
      id
      sources {
        uri
        blurHash
      }
    }
  }
`);
