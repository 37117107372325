import { graphql } from "@apollosproject/cluster-client";

export const EventBlockFeatureFragment = graphql(/* GraphQl */ `
  fragment EventBlockFeatureFragment on EventBlockFeature {
    id
    start
    duration
    allDay
    location
    title
    icsUrl
  }
`);
