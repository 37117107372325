import { graphql } from "@apollosproject/cluster-client";

export const LivestreamFragment = graphql(/* GraphQl */ `
  fragment LivestreamFragment on Livestream {
    id
    title
    summary
    htmlContent
    coverImage {
      ...ImageMediaFragment
    }
    stream {
      ...VideoMediaFragment
    }
    ...LivestreamPlayerFragment
  }
`);
