// helper to render Icons from an icon name from cluster
import { Suspense, lazy } from "react";
import { toPascal } from "ts-case-convert";

import type { IconProps } from "@phosphor-icons/react";

/*
Ok, this is fun.
So the @phosphor icon package is so large that importing all icons at once not
only results in a huge bundle size, Vercel craps out trying to process all the files.

So we're going to use import.meta.glob to build a map of all available icons,
then use React Suspense and lazy to only load the icon we need when we need it.
*/

// Import* types help assert the shape of the import.meta.glob result
type ImportFunction = () => Promise<{
  default: React.ComponentType<unknown>;
  [name: string]: React.ComponentType<unknown>;
}>;

type ImportMap = [string, ImportFunction][];

// Icon* types help assert the shape of the icon import functions used in the component below
type IconFunction = (
  name: string
) => Promise<{ default: React.ComponentType<unknown> }>;

type IconMap = Record<string, IconFunction>;

// Get array of icon import functions
const imports = Object.entries(
  import.meta.glob("/../../node_modules/@phosphor-icons/react/dist/csr/*.mjs")
) as ImportMap;

// We need default exports for React lazy() below, but Phosphor uses named exports only
const importMap: IconMap = imports.reduce((acc: IconMap, [path, fn]) => {
  acc[path] = (name: string) =>
    fn().then((mod) => ({
      default: mod[name],
    }));
  return acc;
}, {});

function relativePathForIcon(name: string) {
  return `../../node_modules/@phosphor-icons/react/dist/csr/${name}.mjs`;
}

export const FeatureIcon = ({
  iconName,
  ...props
}: { iconName?: string } & IconProps) => {
  const iconKey = toPascal(iconName || "");

  const importer = importMap[relativePathForIcon(iconKey)];
  if (!importer) return null;
  const Icon = lazy(() => importer(iconKey));

  return (
    <Suspense fallback={null}>
      <Icon {...props} />
    </Suspense>
  );
};
