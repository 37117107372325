import { graphql } from "@apollosproject/cluster-client";

export const ActionListFeatureFragment = graphql(/* GraphQl */ `
  fragment ActionListFeatureFragment on ActionListFeature {
    title
    subtitle
    actions {
      ...ActionListItemFragment
    }
  }
`);
