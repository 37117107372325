import { graphql } from "@apollosproject/cluster-client";

export const ByLineFragment = graphql(/* GraphQl */ `
  fragment ByLineFragment on Person {
    id
    firstName
    lastName
    photo {
      uri
    }
  }
`);
