import { graphql } from "@apollosproject/cluster-client";

export const VerticalCardListFeatureFragment = graphql(/* GraphQl */ `
  fragment VerticalCardListFeatureFragment on VerticalCardListFeature {
    title
    subtitle
    cards {
      ...FeatureContentCardFragment
    }
  }
`);
