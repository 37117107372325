import { graphql } from "@apollosproject/cluster-client";

export const FeatureFeedFragment = graphql(/* GraphQl */ `
  fragment FeatureFeedFragment on FeatureFeed {
    id
    features {
      ...FeatureFragment
    }
  }
`);

export const FeatureFragment = graphql(/* GraphQl */ `
  fragment FeatureFragment on Feature {
    id
    __typename
    ... on HtmlFeature {
      ...HtmlFeatureFragment
    }
    ... on ScriptureFeature {
      ...ScriptureFeatureFragment
    }
    ... on ActionBarFeature {
      ...ActionBarFeatureFragment
    }
    ... on ActionListFeature {
      ...ActionListFeatureFragment
    }
    ... on EventBlockFeature {
      ...EventBlockFeatureFragment
    }
    ... on ButtonFeature {
      ...ButtonFeatureFragment
    }
    ... on ChipListFeature {
      ...ChipListFeatureFragment
    }
    ... on VerticalCardListFeature {
      ...VerticalCardListFeatureFragment
    }
    ... on HeroListFeature {
      ...HeroListFeatureFragment
    }
    ... on HorizontalCardListFeature {
      ...HorizontalCardListFeatureFragment
    }
    ... on HorizontalMediaListFeature {
      ...HorizontalMediaListFeatureFragment
    }
    ... on QuestionBlockFeature {
      ...QuestionBlockFeatureFragment
    }
    ... on LinkEmbedFeature {
      ...LinkEmbedFeatureFragment
    }
    ... on WebviewFeature {
      ...WebviewFeatureFragment
    }
  }
`);
