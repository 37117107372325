import { graphql } from "@apollosproject/cluster-client";

export const ButtonFeatureFragment = graphql(/* GraphQl */ `
  fragment ButtonFeatureFragment on ButtonFeature {
    id
    action {
      ...FeatureActionButtonFragment
    }
  }
`);
