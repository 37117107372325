import { graphql } from "./gql";

export const getContentItem = graphql(/* GraphQL */ `
  query getContentItem($id: ID!) {
    node(id: $id) {
      id
      __typename
      ... on ContentItem {
        ...ContentItemFragment
      }
    }
  }
`);
