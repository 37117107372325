import { IconContext } from "@phosphor-icons/react/dist/lib/context";
import clsx from "clsx";
import React, { forwardRef } from "react";

import { LinkComponentProps, useLinkComponent } from "../Link";

import type { IconProps } from "@phosphor-icons/react";

const iconContext: IconProps = {
  // defaults, can be overridden by the icon
  color: "var(--icon-color)",
  weight: "fill",
  size: "16px",
};

export const Panel = forwardRef(function PanelWrapper(
  {
    className,
    direction = "cols",
    ...props
  }: React.HTMLAttributes<HTMLDivElement> & { direction?: "cols" | "rows" },
  forwardedRef: React.Ref<HTMLDivElement>
) {
  return (
    <IconContext.Provider value={iconContext}>
      <div
        ref={forwardedRef}
        className={clsx(
          className,

          // Anchor positioning
          "[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.3)] data-[anchor~=end]:[--anchor-offset:4px] data-[anchor~=start]:[--anchor-offset:-4px]",

          // Base styles
          "origin-top-left isolate w-max rounded-lg p-1",

          // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
          "outline outline-1 outline-transparent focus:outline-none",

          // Handle scrolling when menu won't fit in viewport
          "overflow-y-auto",

          // Popover background
          "bg-material-chrome backdrop-blur-xl dark:bg-material-regular-dark",

          // Shadows
          "shadow-lg ring-1 ring-system-thick dark:ring-inset dark:ring-system-thick-dark",

          "supports-[grid-template-columns:subgrid]:grid",

          direction === "cols"
            ? "supports-[grid-template-columns:subgrid]:grid-cols-[auto_auto_auto]"
            : "supports-[grid-template-columns:subgrid]:grid-rows-[auto_auto_auto]",

          "transition data-[closed]:data-[leave]:opacity-0 data-[leave]:duration-100 data-[leave]:ease-in"
        )}
        {...props}
      />
    </IconContext.Provider>
  );
});

type PolymorphicPanelItem =
  | ({ to?: string; href?: string } & LinkComponentProps)
  | ({ to?: never; href?: never } & React.ComponentPropsWithoutRef<"button">);

export const PanelItem = forwardRef(function PanelItem(
  {
    active,
    className,
    href,
    to,
    ...props
  }: {
    active?: boolean;
  } & PolymorphicPanelItem,
  ref: React.ForwardedRef<HTMLButtonElement | HTMLAnchorElement>
) {
  const ownClassName = clsx(
    className,

    // Base styles
    "group cursor-pointer inline-flex rounded-md px-3 py-2 focus:outline-none sm:px-3 sm:py-1.5",

    // Text styles
    "text-left text-base/6 text-fg-primary sm:text-sm/6 dark:text-fg-primary-dark forced-colors:text-[CanvasText]",

    // Focus
    "data-[focus]:bg-system-thin dark:data-[focus]:bg-system-thin-dark",
    "focus:bg-system-thin dark:focus:bg-system-thin-dark",
    "hover:bg-system-thin dark:hover:bg-system-thin-dark",

    // Disabled state
    "data-[disabled]:opacity-50",
    "disabled:opacity-50",

    // Forced colors mode
    "forced-color-adjust-none forced-colors:focus:bg-[Highlight] forced-colors:focus:text-[HighlightText] forced-colors:[&>[data-slot=icon]]:focus:text-[HighlightText]",
    "forced-colors:data-[focus]:bg-[Highlight] forced-colors:data-[focus]:text-[HighlightText] forced-colors:[&>[data-slot=icon]]:data-[focus]:text-[HighlightText]",

    // Use subgrid when available but fallback to an explicit grid layout if not
    "col-span-full grid grid-cols-[auto_auto_auto] items-center supports-[grid-template-columns:subgrid]:grid-cols-subgrid",

    // Icon
    "[--icon-color:theme(colors.fg.secondary)] dark:[--icon-color:theme(colors.fg.secondary-dark)]",
    "gap-x-1",

    active ? "bg-system-thin dark:bg-system-thin-dark" : null
  );

  const Link = useLinkComponent();

  if (href || to) {
    const castedProps = props as LinkComponentProps;
    return (
      <Link
        {...castedProps}
        href={href}
        to={to}
        className={ownClassName}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      />
    );
  } else {
    const castedProps = props as React.ComponentPropsWithoutRef<"button">;
    return (
      <button
        {...castedProps}
        type="button"
        className={ownClassName}
        ref={ref as React.ForwardedRef<HTMLButtonElement>}
      />
    );
  }
});
