import React, { ReactNode } from "react";

interface MutationContextType {
  addComment: (({ text }: { text: string }) => Promise<void>) | null;
  interactWithMedia:
    | (({
        media,
        action,
        data,
      }: {
        media: { id: string };
        action: string;
        data: Record<string, unknown> | undefined;
      }) => Promise<void>)
    | null;
}

export const MutationContext = React.createContext<MutationContextType>({
  addComment: null,
  interactWithMedia: null,
});

interface MutationProviderProps {
  onAddComment: ({ text }: { text: string }) => Promise<void>;
  onInteractWithMedia: ({
    media,
    action,
    data,
  }: {
    media: { id: string };
    action: string;
    data: Record<string, unknown> | undefined;
  }) => Promise<void>;
  children: ReactNode;
}

export const MutationProvider: React.FC<MutationProviderProps> = ({
  onAddComment,
  onInteractWithMedia,
  children,
}) => {
  return (
    <MutationContext.Provider
      value={{
        addComment: onAddComment,
        interactWithMedia: onInteractWithMedia,
      }}
    >
      {children}
    </MutationContext.Provider>
  );
};
