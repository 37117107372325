import { graphql } from "@apollosproject/cluster-client";

export const ActionHandlerFragment = graphql(/* GraphQl */ `
  fragment ActionHandlerFragment on Node {
    id
    __typename
    ... on Url {
      url
    }
    ... on ShareableNode {
      sharing {
        url
      }
    }
  }
`);
