import { graphql } from "@apollosproject/cluster-client";

export const HorizontalCardListFeatureFragment = graphql(/* GraphQl */ `
  fragment HorizontalCardListFeatureFragment on HorizontalCardListFeature {
    title
    subtitle
    cards {
      ...FeatureContentCardFragment
    }
    primaryAction {
      ...FeatureActionButtonFragment
    }
  }
`);
