import { graphql } from "@apollosproject/cluster-client";

export const FeatureActionButtonFragment = graphql(/* GraphQl */ `
  fragment FeatureActionButtonFragment on FeatureAction {
    relatedNode {
      id
      ...ActionHandlerFragment
    }
    action
    title
  }
`);
