import { graphql } from "@apollosproject/cluster-client";

export const FeatureContentCardFragment = graphql(/* GraphQl */ `
  fragment FeatureContentCardFragment on CardListItem {
    id
    action
    title
    hasAction
    actionIcon
    labelText
    summary
    coverImage {
      ...ImageMediaFragment
    }
    relatedNode {
      id
      ...ActionHandlerFragment
    }
  }
`);
