import { createContext, useContext } from "react";

// Define the shape of your context
export interface IChurchAccessTokenContext {
  church: string;
  accessToken?: string;
}

// Create the context with default value as undefined
export const ChurchAccessTokenContext = createContext<
  IChurchAccessTokenContext | undefined
>(undefined);

// Create a custom hook to consume the context
export const useChurchAccessToken = (): IChurchAccessTokenContext => {
  const context = useContext(ChurchAccessTokenContext);
  if (!context) {
    return { church: "apollos_global" };
  }
  return context;
};
