import { graphql } from "@apollosproject/cluster-client";

export const ScriptureItemFragment = graphql(/* GraphQL */ `
  fragment ScriptureItemFragment on Scripture {
    id
    html
    reference
    copyright
  }
`);
