import { graphql } from "@apollosproject/cluster-client";

export const HorizontalMediaListFeatureFragment = graphql(/* GraphQl */ `
  fragment HorizontalMediaListFeatureFragment on HorizontalMediaListFeature {
    title
    subtitle
    items {
      ...FeatureContentCardFragment
      relatedNode {
        ... on Livestream {
          id
          start
          durationInSeconds
        }
      }
    }
    primaryAction {
      ...FeatureActionButtonFragment
    }
  }
`);
