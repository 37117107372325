import * as Headless from "@headlessui/react";
import clsx from "clsx";
import React, { forwardRef } from "react";

import { TouchTarget } from "../Button";
import { LinkComponentProps, useLinkComponent } from "../Link";

interface AvatarProps {
  src?: string | null;
  square?: boolean;
  initials?: string;
  alt?: string;
  className?: string;
}

export const Avatar = ({
  src = null,
  square = false,
  initials,
  alt = "",
  className,
  children,
  ...props
}: AvatarProps & React.ComponentPropsWithoutRef<"span">) => {
  return (
    <span
      data-slot="avatar"
      {...props}
      className={clsx(
        className,

        // Basic layout
        "inline-grid shrink-0 align-middle [--avatar-radius:20%] [--ring-opacity:20%] *:col-start-1 *:row-start-1",
        "bg-material-thin dark:bg-material-thin-dark backdrop-blur-md outline outline-1 -outline-offset-1 outline-black/[--ring-opacity] dark:outline-white/[--ring-opacity]",

        // Add the correct border radius
        square
          ? "rounded-[--avatar-radius] *:rounded-[--avatar-radius]"
          : "rounded-full *:rounded-full"
      )}
    >
      {initials ? (
        <svg
          className="select-none fill-current text-[48px] w-full h-full font-medium uppercase text-fg-secondary dark:text-fg-secondary-dark"
          viewBox="0 0 100 100"
          aria-hidden={alt ? undefined : "true"}
        >
          {alt ? <title>{alt}</title> : null}
          <text
            x="50%"
            y="50%"
            alignmentBaseline="middle"
            dominantBaseline="middle"
            textAnchor="middle"
            dy=".125em"
          >
            {initials}
          </text>
        </svg>
      ) : null}
      {src ? (
        <img src={src} alt={alt} className="w-full h-full object-cover" />
      ) : null}
      {children}
    </span>
  );
};

export const AvatarButton = forwardRef(function AvatarButton(
  {
    src,
    square = false,
    initials,
    alt,
    className,
    avatarClassName,
    children,
    ...props
  }: AvatarProps &
    (
      | Omit<Headless.ButtonProps, "className">
      | Omit<LinkComponentProps, "className">
    ) & {
      avatarClassName?: React.ComponentPropsWithoutRef<
        typeof Avatar
      >["className"];
    },
  ref: React.ForwardedRef<HTMLElement>
) {
  const classes = clsx(
    className,
    square ? "rounded-[20%]" : "rounded-full",
    "relative focus:outline-none data-[focus]:outline data-[focus]:outline-2 data-[focus]:outline-offset-2 data-[focus]:outline-primary-500"
  );

  const Link = useLinkComponent();

  return "href" in props || "to" in props ? (
    <Link
      {...props}
      className={classes}
      ref={ref as React.ForwardedRef<HTMLAnchorElement>}
    >
      <TouchTarget>
        <Avatar
          src={src}
          square={square}
          initials={initials}
          alt={alt}
          className={avatarClassName}
        >
          {children as React.ReactNode}
        </Avatar>
      </TouchTarget>
    </Link>
  ) : (
    <Headless.Button
      {...(props as Headless.ButtonProps)}
      className={classes}
      ref={ref}
    >
      <TouchTarget>
        <Avatar
          src={src}
          square={square}
          initials={initials}
          alt={alt}
          className={avatarClassName}
        >
          {children as React.ReactNode}
        </Avatar>
      </TouchTarget>
    </Headless.Button>
  );
});
