import { graphql } from "@apollosproject/cluster-client";

export const WebviewFeatureFragment = graphql(/* GraphQl */ `
  fragment WebviewFeatureFragment on WebviewFeature {
    id
    title
    height
    url
  }
`);
