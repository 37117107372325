import { graphql } from "@apollosproject/cluster-client";

export const HeroListFeatureFragment = graphql(/* GraphQl */ `
  fragment HeroListFeatureFragment on HeroListFeature {
    title
    subtitle

    heroCard {
      ...FeatureContentCardFragment
    }

    actions {
      ...ActionListItemFragment
    }

    primaryAction {
      ...FeatureActionButtonFragment
    }
  }
`);
