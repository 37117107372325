import { graphql } from "@apollosproject/cluster-client";

export const ActionBarFeatureFragment = graphql(/* GraphQl */ `
  fragment ActionBarFeatureFragment on ActionBarFeature {
    title
    actions {
      id
      icon
      title
      action
      relatedNode {
        id
        ...ActionHandlerFragment
      }
    }
  }
`);
