import { graphql } from "@apollosproject/cluster-client";

export const ChipListFeatureFragment = graphql(/* GraphQl */ `
  fragment ChipListFeatureFragment on ChipListFeature {
    title
    subtitle
    chips {
      title
      action
      iconName
      id
      relatedNode {
        id
        ...ActionHandlerFragment
      }
    }
  }
`);
