import { graphql } from "@apollosproject/cluster-client";

export const ScriptureFeatureFragment = graphql(/* GraphQl */ `
  fragment ScriptureFeatureFragment on ScriptureFeature {
    id
    scriptures {
      id
      ...ScriptureItemFragment
    }
  }
`);
