import { graphql } from "@apollosproject/cluster-client";

export const CommentsSheetFragment = graphql(/* GraphQl */ `
  fragment CommentsSheetFragment on ContentItem {
    id
    comments {
      ...CommentFragment
    }
  }
`);
