import { graphql } from "./gql";

export const getScriptures = graphql(`
  query getScriptures($query: String!) {
    scriptures(query: $query) {
      id
      ...ScriptureItemFragment
    }
  }
`);
