// A provider that provides a component to use for linking

import React, { forwardRef } from "react";

export type LinkComponentProps =
  | ({
      to?: string | { contentId: string | undefined };
      href?: string;
      isExternal?: boolean;
    } & React.AnchorHTMLAttributes<HTMLAnchorElement>)
  | ({
      to?: never;
      href?: string;
      isExternal?: boolean;
    } & React.AnchorHTMLAttributes<HTMLAnchorElement>);

export const LinkComponentContext = React.createContext<
  React.ForwardRefExoticComponent<
    LinkComponentProps & React.RefAttributes<HTMLAnchorElement>
  >
>(
  forwardRef<HTMLAnchorElement, LinkComponentProps>(
    function LinkComponent(props, ref) {
      return <a {...props} ref={ref} />;
    }
  )
);

export const useLinkComponent = () => React.useContext(LinkComponentContext);

interface LinkComponentProviderProps {
  linkComponent: React.ForwardRefExoticComponent<
    LinkComponentProps & React.RefAttributes<HTMLAnchorElement>
  >;
  children: React.ReactNode;
}

export const LinkComponentProvider: React.FC<LinkComponentProviderProps> = ({
  linkComponent,
  children,
}) => {
  return (
    <LinkComponentContext.Provider value={linkComponent}>
      {children}
    </LinkComponentContext.Provider>
  );
};
