import { graphql } from "@apollosproject/cluster-client";

export const CommentFragment = graphql(/* GraphQl */ `
  fragment CommentFragment on Comment {
    id
    text
    person {
      ...ByLineFragment
    }
  }
`);
