import { graphql } from "@apollosproject/cluster-client";

export const ActionListItemFragment = graphql(/* GraphQl */ `
  fragment ActionListItemFragment on ActionListAction {
    id
    title
    subtitle
    action
    image {
      ...ImageMediaFragment
    }
    relatedNode {
      id
      ...ActionHandlerFragment
    }
  }
`);
