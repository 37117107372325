import { createContext, useContext } from "react";

export interface AnalyticsContextShape {
  track(eventName: string, properties?: unknown): unknown;
  identify(userId: string, properties?: unknown): unknown;
}

export const AnalyticsContext = createContext({
  track: (...args) => console.info("Uninitialized Analytics.track", ...args),
  identify: (...args) =>
    console.info("Uninitialized Analytics.identify", ...args),
} as AnalyticsContextShape);

export const useAnalytics = () => useContext(AnalyticsContext);
