import { graphql } from "@apollosproject/cluster-client";

export const ContentItemFragment = graphql(/* GraphQl */ `
  fragment ContentItemFragment on ContentItem {
    id
    title
    summary
    htmlContent
    ...VideoContentItemFragment
    coverImage {
      ...ImageMediaFragment
    }
    videos {
      ...VideoMediaFragment
    }
    commentsEnabled
    ...CommentsSheetFragment
    ... on FeaturesNode {
      featureFeed {
        id
        ...FeatureFeedFragment
      }
    }
    sharing {
      ...ShareButtonFragment
    }
    parentItem {
      ...ContentItemCardFragment
    }
    childContentItemsConnection {
      edges {
        node {
          ...ContentItemCardFragment
        }
      }
    }
    siblingContentItemsConnection {
      edges {
        node {
          ...ContentItemCardFragment
        }
      }
    }
  }
`);
